@import "../sizing";

.serviceList {
  margin: auto;
  display: flex;
  gap: 5%;
  justify-content: center;
  padding: 0 50px;
  width: 80vw;

  @media (max-width: $small-device-width) {
    flex-direction: column;
    align-items: center;
    padding: 0;
  }
}
