@import '../colors';
@import '../sizing';


.headlineContainer {
  background-color: $light-bg;
  display: flex;
  justify-content: center;

  @media (max-width: $small-device-width) {
    margin: 0;
  }

  .imageContainer {
    position: relative;
    height: 500px;
    width: 100vw;
    background-position: center;
    background-size: cover;
    animation: fadeInAnimation ease 2s;
    @keyframes fadeInAnimation {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    @media (max-width: $small-device-width) {
      width: 100%;
      height: 300px;
      border-radius: 0;
    }

    .title {
      position: absolute;
      bottom: 0;
      width: 100%;
      box-sizing: border-box;
      padding: 30px 30px;
      color: $dark-overlay-fg;
      background-color: $dark-overlay-bg;
      font-size: 40px;
      font-weight: 300;
      letter-spacing: 1px;

      @media (max-width: $small-device-width) {
        font-size: 1em;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
}
