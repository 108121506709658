.serviceDetailsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px;

  .thumbnail {
    height: 300px;
    width: 300px;
    object-fit: cover;
    border-radius: 50%;
  }
}
